/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Naše služby"} description={"Naše služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--62 w--900" style={{"maxWidth":700}} content={"Naše služby"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah"}>
          
          <ColumnWrap className="column__flex --left el--3" style={{"maxWidth":1200}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image src={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-1_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Okamžitý výkup"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":467}} content={"Vykúpime Vašu nehnuteľnosť, rýchlo a za férovú cenu. Vykupujeme nehnuteľnosti v rámci celej Slovenskej republiky a Českej republiky."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image src={"https://cdn.swbpg.com/t/i/template/132/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-2_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Peniaze ešte pred predajom"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":467}} content={"Potrebujete určitú finančnú čiastku ihneď a zároveň chcete dostať za nehnuteľnosť najvyššiu možnú sumu? My Vám vyplatíme dohodnutú čiastku vopred a nehnuteľnosť môžete ďalej predávať za trhovú cenu. S predajom nehnuteľností Vám radi pomôžeme.."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Image src={"https://cdn.swbpg.com/t/i/template/132/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/132/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/132/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/132/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/132/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/132/img-3_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":286}} content={"Zbavíme Vás dlhov a zostanete bývať vo vlastnom"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":400}} content={"Dostali ste sa do dlhovej pasce, máte exekúcie alebo Vám hrozí dražba a nechcete prísť o svoje bývanie? Využite našu službu spätného leasingu nehnuteľnosti. Vaše dlhy vyplatíme a Vy budete môcť naďalej zostať bývať u seba doma."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"prázdná"} layout={"l30"}>
        </Column>


        <Column className="css-g943z5 --full pb--80 pt--80" name={"kontakt"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/132/fullscreen-2_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: var(--white);\">Vykúpime Vašu nehnuteľnosť rýchlo a férovo.</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape4" use={"page"} href={"/kontakt#kontakt"} content={"Kontaktovat"}>
              </Button>

              <Text className="text-box fs--72" content={"<span style=\"font-weight: bold; color: var(--white);\">0907 865 803</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--color-dominant)\">World Capital Trade - LL</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--white);\">+421907865803</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}